export const resetWheel = (category_text = "") => {
  document.getElementById('Category').innerHTML = category_text;
  document.getElementById('Portion').innerHTML = "";
  document.getElementById('Drink').innerHTML = "";
  document.getElementById('Mixer').innerHTML = "";
}

export const getRandomItem = (keys) => {
  return keys[keys.length * Math.random() << 0];
}

export const toggleRandomisable = () => {
  document.getElementById("random-button").toggleAttribute("disabled");
}

export const startRandomisation = (animationTimeout) => {
  let button = document.getElementById('random-button');
  button.classList.add("random-spin");
  button.toggleAttribute("disabled");
  setTimeout(() =>  {
      button.classList.remove("random-spin");
      button.toggleAttribute("disabled");
  }, animationTimeout);
}

export const getMultipleDrinks = (category, drinks) => {
  let multiple_drinks = [];
  if (category.includes('2 for')) multiple_drinks.push(getRandomItem(drinks));
  if (category.includes('3 for')){
    multiple_drinks.push(getRandomItem(drinks))
    multiple_drinks.push(getRandomItem(drinks))
  }
  return multiple_drinks;
}

export const setRandomResults = (category, portion, drink, mixer, animationTimeout) => {
  let randomedVariables = [{"category": category}, {"portion": portion}, {"drink": drink}, {"mixer": mixer}].filter((e) => Object.values(e)[0]);
  let randomedCount = randomedVariables.length;
  
  randomedVariables.forEach((element, index) => {
    setTimeout(() => {
      switch(Object.keys(element)[0]){
        case "category":
          document.getElementById('Category').innerHTML = category;
          break;
        case "portion":
          document.getElementById('Portion').innerHTML = portion.name
          break;
        case "drink":
          document.getElementById('Drink').innerHTML = drink.name;
          break;
        case "mixer":
          document.getElementById('Mixer').innerHTML = mixer.name;
          break;

      }
    }, animationTimeout/randomedCount * (index + 1))
  });
}