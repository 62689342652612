import React from "react"

import "../styles/settings-window.css";

function SettingsWindow({pageStates, activeCategories, setActiveCategories, extras, setExtras, weightByCategory, setWeightByCategory}) {

    const handleCategoryCheck = (e) => {
        let category = e.target.name;
        if (Object.values(activeCategories).filter(Boolean).length <= 1 && activeCategories[category]) return;
        setActiveCategories((activeCategories) => ({
            ...activeCategories, [category]: !activeCategories[category],
        }));
    }

    const handleExtrasCheck = (e) => {
        let checkbox = e.target.name
        setExtras((extras) => ({
            ...extras, [checkbox]: !extras[checkbox],
        }));
    }

    const handleWeightCheck = (e) => {
        setWeightByCategory(!weightByCategory);
    }

    return <div className="settings-window" style={{display : pageStates.settings ? "block" : "none"}}>
        <div className="settings-inner">

            <div className="settings-section">
                <span className="checkbox settings-header">Additional Randomisations</span>
                <label className="settings-checkbox-row">
                    <input type="checkbox" name="portions" checked={extras["portions"]} onChange={handleExtrasCheck} ></input>
                    <span class="checkbox">Random Portions</span>
                </label>

                <label className="settings-checkbox-row">
                    <input type="checkbox" name="mixers" checked={extras["mixers"]} onChange={handleExtrasCheck} ></input>
                    <span class="checkbox">Random Mixers</span>
                </label>
            </div>

            <div className="settings-section">
                <span className="checkbox settings-header">Select Categories</span>
                {Object.keys(activeCategories).map((category, index) =>
                    <label key={index} className="settings-checkbox-row">
                        <input type="checkbox" name={category} checked={!!activeCategories[category]} onChange={handleCategoryCheck} ></input>
                        <span class="checkbox">{category}</span>
                    </label>
                )}
            </div>

            <div className="settings-section">
            <span className="checkbox settings-header">Randomisation Weights</span>
                <label className="settings-checkbox-row">
                    <input type="radio" id="weight-drink" name="weight" onChange={handleWeightCheck}></input>
                    <span class="checkbox">By drinks</span>
                </label>
                <label className="settings-checkbox-row">
                    <input type="radio" id="weight-category" defaultChecked name="weight" onChange={handleWeightCheck}></input>
                    <span class="checkbox">By category</span>
                </label>
            </div>
            
        </div>
    </div>
}

export default SettingsWindow;
