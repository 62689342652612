import React from "react";
import {resetWheel, getRandomItem, startRandomisation, getMultipleDrinks, setRandomResults} from "../scripts/utils"

import "../styles/randomizer.css";

const ANIMATION_TIMEOUT = 7500;

function Randomizer({randomizationData, activeCategories, extras, weightByCategory, settingsDisplay}) {

  const handleRandomization = () => {
    
    if (document.getElementById('random-button').disabled) return;

    startRandomisation(ANIMATION_TIMEOUT);
    resetWheel('Randomizing...');

    let category = "";
    let drink = "";
    let portion = "";
    let mixer = "";
    let multiple_drinks = [];

    if (weightByCategory) {
      let categories = Object.keys(randomizationData.drinks).filter((category) => {
        return activeCategories[category];
      });
      category = getRandomItem(categories);

      let drinks = randomizationData.drinks[category];
      drink = getRandomItem(drinks);
      
      multiple_drinks = getMultipleDrinks(category, drinks);
    } else {
      let drinks = Object.values(randomizationData.drinks).flat().filter((drink) => {
        return activeCategories[drink.parent];
      });
      drink = getRandomItem(drinks);
      category = drink.parent;

      multiple_drinks = getMultipleDrinks(category, drinks);
    }
    if (category.includes("2 for") || category.includes('3 for')) {
      portion = multiple_drinks.pop();
      if (category.includes("3 for")) {
        mixer = multiple_drinks.pop();
      }
    } else {
      if (extras.portions && drink.portions) {
        let portions = Object.values(drink.portions);
        portion = getRandomItem(portions);
      }
      if (extras.mixers && drink.mixersId) {
        let mixers = Object.values(randomizationData.mixers[drink.mixersId]).flat();
        mixer = getRandomItem(mixers);
      }
    }
    
    setRandomResults(category, portion, drink, mixer, ANIMATION_TIMEOUT);
  }


return <div className="random-container">
  <div className="random-location" style={{display : randomizationData.location ? 'block' : 'none'}}>
    {randomizationData.location}
  </div>
  <div className="random-inner">
    <button id="random-button" onClick={handleRandomization}>
    </button>
    <div className="random-results" onClick={handleRandomization}>
      <div id="Category">Randomise!</div>
      <div id="Portion"></div>
      <div id="Drink"></div>
      <div id="Mixer"></div>
    </div>
  </div>
</div>

}

export default Randomizer;
