import React from "react";
import "../styles/filter.css";

function Filter({pageStates}) {
    return <div className="filter" style={{display : pageStates.filter ? 'block' : 'none'}}>
        
    </div>
}

export default Filter;
