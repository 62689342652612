import React, { useState } from "react";
import { Helmet } from "react-helmet";

import "../styles/main.css"
import "../styles/reset.css"
import "../styles/variables.css"

import LocationModal from "../templates/LocationModal"
import NavBar from "../templates/NavBar";
import SettingsWindow from "../templates/SettingsWindow";
import Randomizer from "../templates/Randomizer";
import Filter from "../templates/Filter";

const location_json = require('../../venues.json');

// markup
const IndexPage = () => {

  const [pageStates, setPageStates] = useState({location: true, settings: false, filter: true})

  const [randomizationData, setRandomizationData] = useState({drinks : "", mixers : "", location : ""})
  const [activeCategories, setActiveCategories] = useState({})
  const [extras, setExtras] = useState({mixers: true, portions: true})
  const [weightByCategory, setWeightByCategory] = useState(true)

  return (
      <main className="page-main">
        <Helmet>
          <title>SpoonsRoulette - Let it chose!</title>
        </Helmet>
        <NavBar
          pageStates={pageStates}
          setPageStates={setPageStates} />

        <Filter pageStates={pageStates} />

        <div className="page-inner">
          <SettingsWindow
            pageStates={pageStates}
            activeCategories={activeCategories}
            setActiveCategories={setActiveCategories}
            extras={extras}
            setExtras={setExtras}
            weightByCategory={weightByCategory}
            setWeightByCategory={setWeightByCategory} />

          <LocationModal
            locations={location_json}
            pageStates={pageStates}
            setPageStates={setPageStates}
            setActiveCategories={setActiveCategories}
            randomizationData={randomizationData}
            setRandomizationData={setRandomizationData} />

          <Randomizer
            randomizationData={randomizationData}
            activeCategories={activeCategories}
            extras={extras}
            weightByCategory={weightByCategory}
            pageStates={pageStates} />
        </div>
      </main>
  )
}

export default IndexPage
