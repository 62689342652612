import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faMapLocationDot, faTimes } from '@fortawesome/free-solid-svg-icons';

import "../styles/nav-bar.css";

function NavBar({pageStates, setPageStates}) {

  const onSettingsClick = () => {
    if (!pageStates.location) {
      setPageStates((pageStates) => ({
        ...pageStates, ['settings']: !pageStates['settings'],
      }));
    }
  }

  const onLocationClick = () => {
    if (!pageStates.settings) {
      setPageStates((pageStates) => ({
        ...pageStates, ['location']: true, ['filter']: true,
      }));
    }
  }

  return <nav className="nav-bar">
    <div className="nav-inner">
      <div className="nav-location">
        <FontAwesomeIcon icon={faMapLocationDot} size="xl" fixedWidth onClick={onLocationClick} />
      </div>
      <span className="nav-title">
        <div style={{display : pageStates.settings ? "block" : "none"}}>Settings</div>
        <div id="nav-emoji" style={{display : pageStates.settings ? "none" : "block"}}>Spoons Roulette</div>
      </span>
      <div className="nav-settings">
        <FontAwesomeIcon icon={faGear} size="xl" fixedWidth  onClick={onSettingsClick} style={{display : pageStates.settings ? "none" : "block"}}/>
        <FontAwesomeIcon icon={faTimes} size="xl" fixedWidth  onClick={onSettingsClick} style={{display : pageStates.settings ? "block" : "none"}} />
      </div>
    </div>
  </nav>;
}

export default NavBar;
