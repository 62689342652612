import React from "react"
import { resetWheel, toggleRandomisable } from "../scripts/utils"
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../styles/location-modal.css";

function LocationModal({locations, pageStates, setPageStates, randomizationData, setRandomizationData, setActiveCategories}) {

  const onLocationChange = (e) => {  
    setPageStates((pageStates) => ({
      ...pageStates, ['location']: false, ['filter']: false,
    }));

    resetWheel("Loading...");
    toggleRandomisable();

    let venueName = e.name;
    let iOrderId = e.iorderid;
    let venueId = e.value;
    
    try{
      fetch('/api/getDrinks', {
        method: 'POST',
        body: new URLSearchParams({
          iOrderId: iOrderId,
          venueId: venueId,
        })
      })
      .then(response => response.json())
      .then(data => {
        setRandomizationData({
          drinks : data.drinks,
          mixers : data.categories,
          location : venueName,
        })
        setActiveCategories(Object.fromEntries(Object.keys(data.drinks).map((e) => [e, true])))

        resetWheel("Randomise!");
        toggleRandomisable();
      })
    }catch{
      console.log("ERROR : TO DO NOTIFICATION");
    }
  }

  const handleCancelLocationChange = () => {
    setPageStates((pageStates) => ({
      ...pageStates, ['location']: false, ['filter']: false,
    }));
  }

  const location_options = locations.venues.map(e => 
    ({value: e.venueId, label: `${e.name} - ${e.town}`, name: e.name, iorderid: e.iOrderId})
  )


  return <div className="location-background" style={{display : pageStates.location ? "grid" : "none"}}>
    <div className="location-modal">
      <div className="location-close">
        <FontAwesomeIcon icon={faTimes} size="xl" fixedWidth  style={{display : randomizationData.drinks !== "" ? "block" : "none"}} onClick={handleCancelLocationChange} />
      </div>
      <div className="location-container">
        <div className="location-welcome">Select Your Venue</div>

        <Select placeholder="Search for your venue..." options={location_options} onChange={onLocationChange} />

          <div className="location-info">
            This is only used to get the latest menu information
          </div>
      </div>
    </div>
  </div>
}

export default LocationModal;
